import { useEffect, useState, useContext, useCallback } from "react";
import {
  Stack,
  ShimmeredDetailsList,
  IColumn,
  buildColumns,
  SelectionMode,
  IListProps,
  SearchBox,
  ISearchBoxStyles,
  Dropdown,
  IDropdownOption,
  IDropdownStyles,
  DefaultButton,
  MarqueeSelection,
  Selection,
  CommandBar,
  ICommandBarItemProps,
  DetailsListLayoutMode
} from "@fluentui/react";
import { context } from "AppContext";
import { IProductListEntry } from "models/IProductListEntry";
import ReactPaginate from "react-paginate";
import { useLocation } from "react-router";
import {
  ProductAuthorizationToString,
  ProductBackendHostingToString,
  ProductBackendRoutingToString,
  ProductCategory,
  ProductType,
  ProductCategoryToString,
  ProductConfidentialityToString,
  ProductTypeToString,
  ProductBackendHosting,
  ProductBackendRouting,
  ProductAuthorization,
  ProductConfidentiality
} from "models/ProductMetadataEnums";
import { ProductFormPanel } from "components/ProductManagement/ProductFormPanel";
import queryString from "query-string";
import { IProduct } from "models/IProduct";
import { ClientAppPanel } from "components/ClientAppManagement/ClientAppPanel";
import { ProductBackendOverviewPanel } from "./ProductBackendOverviewPanel";
import { ProductOwnerPanel } from "components/ProductManagement/ProductOwnerPanel";
import Logger from "Logger";
import { ProductApisPanel } from "./ProductApisPanel";
import { ProductSubscriptionsPanel } from "components/SubscriptionManagement/ProductSubscriptionsPanel";
import { copyAndSort, trimLower } from "helpers/HelperFunctions";
import { mapEnumToDropDownValuesWithUndefinedValue } from "components/Core/DropDownUtils";
import { Loading } from "components/Core/Loading";
import { ProductComplianceInfo } from "../ComplianceWarnings/ProductComplianceInfo";
import { ComplianceWarningsPanel } from "components/ComplianceWarnings/ComplianceWarningsPanel";
import { PAGE_SIZE } from "helpers/const";
import { onRenderProductListRow } from "../Core/DetailsListUtils";

export const ProductList = () => {
  const ctx = useContext(context)!;
  const urlParams: queryString.ParsedQuery = queryString.parse(useLocation().search);
  const [currentProducts, setCurrentProducts] = useState<IProductListEntry[]>(
    []
  );
  const [currentProductsPage, setCurrentProductsPage] = useState(
    [] as IProductListEntry[]
  );
  const [loadingProduct, setLoadingProduct] = useState<boolean>(false);
  const [loadingExportSubscriptions, setLoadingExportSubscriptions] =
    useState<boolean>(false);
  const [columns, setColumns] = useState([] as IColumn[]);
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [selectedProductListEntry, setSelectedProductListEntry] =
    useState<IProductListEntry>();
  const [loadedProduct, setLoadedProduct] = useState<IProduct>();
  const [productSelection] = useState<Selection>(
    new Selection({
      onSelectionChanged: () => {
        setSelectedProductListEntry(
          productSelection.getSelection()[0] as IProductListEntry
        );
      }
    }) as any
  );
  const [showProductPanel, setShowProductPanel] = useState<boolean>(false);
  const [showProductOwnerPanel, setShowProductOwnerPanel] =
    useState<boolean>(false);
  const [showClientAppsPanel, setShowClientAppsPanel] =
    useState<boolean>(false);
  const [showProductBackendOverviewPanel, setShowProductBackendOverviewPanel] =
    useState<boolean>(false);
  const [showProductApisPanel, setShowProductApisPanel] =
    useState<boolean>(false);
  const [showProductSubscriptionsPanel, setShowProductSubscriptionsPanel] =
    useState<boolean>(false);
  const [showComplianceWarningsPanel, setShowComplianceWarningsPanel] =
    useState<boolean>(false);
  const [searchText, setSearchText] = useState("");
  const [filteredCategory, setFilteredCategory] = useState<number | undefined>(
    urlParams.category ? +urlParams.category.toString() : undefined
  );
  const [filteredConfidentiality, setFilteredConfidentiality] = useState<
    number | undefined
  >(
    urlParams.confidentiality
      ? +urlParams.confidentiality.toString()
      : undefined
  );
  const [filteredTypes, setFilteredTypes] = useState<number[]>(
    urlParams.types
      ?.toString()
      .split(",")
      .map((c) => +c) || []
  );
  const [filteredBackendHosting, setFilteredBackendHosting] = useState<
    number[]
  >(
    urlParams.backendHostings
      ?.toString()
      .split(",")
      .map((c) => +c) || []
  );
  const [filteredBackendRouting, setFilteredBackendRouting] = useState<
    number[]
  >(
    urlParams.backendRoutings
      ?.toString()
      .split(",")
      .map((c) => +c) || []
  );
  const [filteredAuthorization, setFilteredAuthorization] = useState<number[]>(
    urlParams.authorizations
      ?.toString()
      .split(",")
      .map((c) => +c) || []
  );
  const [filteredKeyVaultUsage, setFilterdKeyVaultUsage] = useState<
    number | undefined
  >(urlParams.keyVaultUsage ? +urlParams.keyVaultUsage.toString() : undefined);
  const [showFilters, setShowFilters] = useState<boolean>(
    urlParams.filtered === "true"
  );

  const searchBoxStyles: Partial<ISearchBoxStyles> = { root: { width: 200 } };
  const filterDropdownStylesEmpty: Partial<IDropdownStyles> = {
    dropdown: { width: 200 }
  };
  const filterDropdownStylesFiltered: Partial<IDropdownStyles> = {
    dropdown: { width: 200 },
    title: { backgroundColor: "#edebe9" }
  };

  const shimmeredDetailsListProps: IListProps = {
    renderedWindowsAhead: 0,
    renderedWindowsBehind: 0
  };

  const commandBarItems: ICommandBarItemProps[] = [
    {
      key: "edit",
      text: "Edit",
      iconProps: { iconName: "Edit" },
      onClick: () => setShowProductPanel(true),
      disabled: selectedProductListEntry === undefined
    },
    {
      key: "owners",
      text: "Product Owners",
      iconProps: { iconName: "Group" },
      onClick: () => setShowProductOwnerPanel(true),
      disabled: selectedProductListEntry === undefined
    },
    {
      key: "oauthClients",
      text: "Oauth2 Clients",
      iconProps: { iconName: "Permissions" },
      onClick: () => setShowClientAppsPanel(true),
      disabled: selectedProductListEntry === undefined
    },
    {
      key: "backends",
      text: "Oauth2 Backends",
      iconProps: { iconName: "Database" },
      onClick: () => setShowProductBackendOverviewPanel(true),
      disabled: selectedProductListEntry === undefined
    },
    {
      key: "apis",
      text: "Product APIs",
      iconProps: { iconName: "AzureAPIManagement" },
      onClick: () => setShowProductApisPanel(true),
      disabled: selectedProductListEntry === undefined
    },
    {
      key: "subscriptions",
      text: "Product Subscriptions",
      iconProps: { iconName: "AzureKeyVault" },
      onClick: () => setShowProductSubscriptionsPanel(true),
      disabled: selectedProductListEntry === undefined
    },
    {
      key: "subscriptions-csv",
      text: `Export all ${ctx.apimEnvironment} subscriptions`,
      iconProps: { iconName: "DownloadDocument" },
      onClick: () => {
        handleDownloadSubscriptionsCsv();
      },
      disabled: currentProducts.length === 0
    },
    {
      key: "clientapps-csv",
      text: `Export all ${ctx.apimEnvironment} client apps`,
      iconProps: { iconName: "DownloadDocument" },
      onClick: () => {
        handleDownloadClientAppCsv();
      },
      disabled: currentProducts.length === 0
    }
  ];

  const handleDownloadSubscriptionsCsv = () => {
    setLoadingExportSubscriptions(true);
    ctx.backendClient
      .downloadSubscriptionsCsv(ctx.apimEnvironment)
      .catch((error) => Logger.Error(error))
      .finally(() => setLoadingExportSubscriptions(false));
  };

  const handleDownloadClientAppCsv = () => {
    setLoadingExportSubscriptions(true);
    ctx.backendClient
      .downloadClientAppsCsv(ctx.apimEnvironment)
      .catch((error) => Logger.Error(error))
      .finally(() => setLoadingExportSubscriptions(false));
  };

  const getSelectedAsBitFlags = (collection: number[]): number => {
    return collection.reduce(
      (allSelected, currentSelected) => currentSelected | allSelected,
      0
    );
  };

  const checkIfSingleFlag = (x: number): boolean => {
    return x !== 0 && (x & (x - 1)) === 0;
  };

  useEffect(() => {
    setCurrentProducts(ctx.products);
  }, [ctx.products]);

  useEffect(() => {
    productSelection.setAllSelected(false);
    setSelectedProductListEntry(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProducts]);

  // apply paging based on current page and page size
  useEffect(() => {
    const offset = currentPageNumber * PAGE_SIZE;
    setCurrentProductsPage(currentProducts.slice(offset, offset + PAGE_SIZE));
  }, [currentPageNumber, currentProducts]);

  // apply search text and all filters on metadata
  useEffect(() => {
    const filteredProducts = ctx.products.slice().filter((product) => {
      if (
        trimLower(product.displayName).indexOf(trimLower(searchText)) === -1
      ) {
        return false;
      }
      if (
        (filteredCategory && filteredCategory !== product.category!) ||
        (filteredCategory === 0 && product.category)
      ) {
        return false;
      }
      if (
        (filteredConfidentiality &&
          filteredConfidentiality !== product.confidentiality!) ||
        (filteredConfidentiality === 0 && product.confidentiality)
      ) {
        return false;
      }
      if (!filteredTypes.includes(-1)) {
        if (
          product.type && filteredTypes.length > 0
            ? getSelectedAsBitFlags(filteredTypes) !== product.type
            : filteredTypes.length > 0 && !filteredTypes.includes(0)
        ) {
          return false;
        }
      } else {
        if (
          (product.type && checkIfSingleFlag(product.type)) ||
          !product.type
        ) {
          return false;
        }
      }
      if (
        product.backendHosting && filteredBackendHosting.length > 0
          ? getSelectedAsBitFlags(filteredBackendHosting) !==
            product.backendHosting
          : filteredBackendHosting.length > 0 &&
            !filteredBackendHosting.includes(0)
      ) {
        return false;
      }
      if (
        product.backendRouting && filteredBackendRouting.length > 0
          ? getSelectedAsBitFlags(filteredBackendRouting) !==
            product.backendRouting
          : filteredBackendRouting.length > 0 &&
            !filteredBackendRouting.includes(0)
      ) {
        return false;
      }
      if (!filteredAuthorization.includes(-1)) {
        if (
          product.authorization && filteredAuthorization.length > 0
            ? getSelectedAsBitFlags(filteredAuthorization) !==
              product.authorization
            : filteredAuthorization.length > 0 &&
              !filteredAuthorization.includes(0)
        ) {
          return false;
        }
      } else {
        if (product.authorization && checkIfSingleFlag(product.authorization)) {
          return false;
        }
      }
      if (
        (filteredKeyVaultUsage === 1 && product.isUsingKeyVault !== true) ||
        (filteredKeyVaultUsage === 2 && product.isUsingKeyVault !== false) ||
        (filteredKeyVaultUsage === 0 && product.isUsingKeyVault !== undefined)
      ) {
        return false;
      }
      return true;
    });
    setCurrentProducts(filteredProducts);
    setCurrentPageNumber(0);
  }, [
    ctx.products,
    searchText,
    filteredCategory,
    filteredConfidentiality,
    filteredTypes,
    filteredBackendHosting,
    filteredBackendRouting,
    filteredAuthorization,
    filteredKeyVaultUsage
  ]);

  // build DetailsList columns
  useEffect(() => {
    if (columns.length < 2) {
      let newColumns = [
        {
          key: "compliance",
          name: "Compliance",
          fieldName: "compliance",
          minWidth: 150,
          maxWidth: 150,
          isCollapsable: false,
          isCollapsible: false,
          isGrouped: false,
          isMultiline: false,
          isResizable: false,
          isRowHeader: false,
          isSorted: false,
          isSortedDescending: false,
          onRender: (product: IProduct) => {
            return (
              <ProductComplianceInfo
                product={product}
                onClick={() => {
                  setShowComplianceWarningsPanel(true);
                }}
              />
            );
          }
        },
        ...buildColumns(ctx.products)
        ];
      newColumns = newColumns.filter(
        (column) =>
          column.key !== "id" &&
          column.key !== "backendAppIdForDevApim" &&
          column.key !== "backendAppIdForTestApim" &&
          column.key !== "backendAppIdForProdApim" &&
          column.key !== "productOwners" &&
          column.key !== "state" &&
          column.key !== "subscriptionRequired" &&
          column.key !== "subscriptionsLimit" &&
          column.key !== "terms" &&
          column.key !== "approvalRequired" &&
          column.key !== "clientApps" &&
          column.key !== "deletedFromApimDev"
        );
        for (const column of newColumns) {
        switch (column.key) {
            case "isUsingKeyVault":
              column.minWidth = 100;
              column.maxWidth = 100;
              column.name = "Uses KeyVault";
              break;
            case "displayName":
              column.minWidth = 200;
              column.maxWidth = 200;
              column.name = "Name";
              break;
            case "category":
              column.minWidth = 75;
              column.maxWidth = 75;
              column.name = "Category";
              break;
            case "confidentiality":
              column.minWidth = 100;
              column.maxWidth = 100;
              column.name = "Confidentiality";
              break;
            case "type":
              column.minWidth = 125;
              column.maxWidth = 125;
              column.name = "Type";
              break;
            case "backendHosting":
              column.minWidth = 150;
              column.maxWidth = 150;
              column.name = "Backends hosted";
              break;
            case "backendRouting":
              column.minWidth = 125;
              column.maxWidth = 125;
              column.name = "Backends routed";
              break;
            case "authorization":
              column.minWidth = 150;
              column.maxWidth = 150;
              column.name = "Client Authorization";
              break;
        }
      }
      setColumns(newColumns);
    }
  }, [ctx.products, columns.length]);

  const loadProductDetails = useCallback(() => {
    if (selectedProductListEntry) {
      setLoadingProduct(true);
      ctx.backendClient
        .getProduct(selectedProductListEntry.id, ctx.apimEnvironment)
        .then((res) => {
          setLoadedProduct(res);
          setLoadingProduct(false);
        })
        .catch((err) => {
          setLoadedProduct(undefined);
          Logger.Error(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProductListEntry, ctx.apimEnvironment]);

  // load product details on selection of product
  useEffect(() => {
    loadProductDetails();
  }, [loadProductDetails]);

  const clearFilters = () => {
    setFilteredCategory(undefined);
    setFilteredConfidentiality(undefined);
    setFilteredTypes([]);
    setFilteredBackendHosting([]);
    setFilteredBackendRouting([]);
    setFilteredAuthorization([]);
    setFilterdKeyVaultUsage(undefined);
    productSelection.setAllSelected(false);
    setSelectedProductListEntry(undefined);
  };

  const onMetadataFilterChanged = (
    selectedItems: number[],
    setSelectedItemsFunction: React.Dispatch<React.SetStateAction<number[]>>
  ) => {
    return (
      event: React.FormEvent<HTMLDivElement>,
      item: IDropdownOption | undefined
    ): void => {
      if (item) {
        setSelectedItemsFunction(
          item.selected
            ? [...selectedItems, item.key as number]
            : selectedItems.filter((key) => key !== item.key)
        );
      }
    };
  };

  const renderItemColumn = (
    item: IProductListEntry,
    index: number | undefined,
    column: IColumn | undefined
  ) => {
    if (column) {
      const fieldContent = item[
        column.fieldName as keyof IProductListEntry
      ] as string;
      switch (column.key) {
        case "category":
          return <span>{ProductCategoryToString(fieldContent)}</span>;
        case "confidentiality":
          return <span>{ProductConfidentialityToString(fieldContent)}</span>;
        case "type":
          return <span>{ProductTypeToString(fieldContent)}</span>;
        case "backendHosting":
          return <span>{ProductBackendHostingToString(fieldContent)}</span>;
        case "backendRouting":
          return <span>{ProductBackendRoutingToString(fieldContent)}</span>;
        case "authorization":
          return <span>{ProductAuthorizationToString(fieldContent)}</span>;
        case "isUsingKeyVault":
          return (
            <span>
                {fieldContent === undefined
                    ? "Undefined"
                    : fieldContent!.toString() === "true"
                        ? "Yes"
                        : "No"}
            </span>
          );
        default:
          return <span>{fieldContent}</span>;
      }
    }
    return <span></span>;
  };

  const onPageChanged = (page: { selected: number }) => {
    setCurrentPageNumber(page.selected);
    productSelection.setAllSelected(false);
    setSelectedProductListEntry(undefined);
  };

  const onColumnClick = (
    ev: React.MouseEvent<HTMLElement> | undefined,
    column: IColumn | undefined
  ): void => {
    if (column) {
      const newColumns: IColumn[] = [...columns];
      const currColumn: IColumn = newColumns.filter(
        (currCol) => column.key === currCol.key
      )[0];
      newColumns.forEach((newCol: IColumn) => {
        if (newCol === currColumn) {
          currColumn.isSortedDescending = !currColumn.isSortedDescending;
          currColumn.isSorted = true;
        } else {
          newCol.isSorted = false;
          newCol.isSortedDescending = true;
        }
      });
      const newlySortedProducts = copyAndSort(
        ctx.products,
        currColumn.fieldName!,
        currColumn.isSortedDescending
      );
      setColumns(newColumns);
      productSelection.setAllSelected(false);
      ctx.setProducts(newlySortedProducts);
    }
    };

  return (
    <Stack>
      <Loading
        loading={
          loadingExportSubscriptions ||
          ctx.loadingProducts ||
          ctx.loadingComplianceWarnings
        }
      />
      <h3>
        API Products on{" "}
        <span
          style={{
            color: `${
              ctx.apimEnvironment === "DEV"
                ? "#1FFF5A"
                : ctx.apimEnvironment === "TEST"
                ? "orange"
                : "#95233A"
            }`
          }}
        >
          {ctx.apimEnvironment}
        </span>
      </h3>
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <SearchBox
          styles={searchBoxStyles}
          placeholder="Search by name"
          onEscape={() => {
            setSearchText("");
            productSelection.setAllSelected(false);
            setCurrentPageNumber(0);
          }}
          onClear={() => {
            setSearchText("");
            productSelection.setAllSelected(false);
            setCurrentPageNumber(0);
          }}
          onChange={(_, newSearchText) => {
            setSearchText(newSearchText ?? "");
            productSelection.setAllSelected(false);
            setCurrentPageNumber(0);
          }}
          onSearch={(newSearchText) => {
            setSearchText(newSearchText ?? "");
            productSelection.setAllSelected(false);
            setCurrentPageNumber(0);
          }}
        />
        <DefaultButton
          toggle
          checked={showFilters}
          text={showFilters ? "Clear filters" : "Filter"}
          iconProps={
            showFilters ? { iconName: "ClearFilter" } : { iconName: "Filter" }
          }
          onClick={() => {
            clearFilters();
            setShowFilters(!showFilters);
          }}
          allowDisabledFocus
        />
        {showFilters && (
          <Stack
            horizontal
            tokens={{ childrenGap: 10 }}
            styles={{ root: { position: "relative", top: "-31px" } }}
          >
            <Dropdown
              placeholder="Filter Category"
              label="Category"
              selectedKey={filteredCategory}
              onChange={(
                event: React.FormEvent<HTMLDivElement>,
                item: IDropdownOption | undefined
              ): void => {
                if (item) {
                  setFilteredCategory(item.key as number);
                }
              }}
              options={mapEnumToDropDownValuesWithUndefinedValue(
                ProductCategory,
                ProductCategoryToString
              )}
              styles={
                filteredCategory !== undefined
                  ? filterDropdownStylesFiltered
                  : filterDropdownStylesEmpty
              }
            />
            <Dropdown
              placeholder="Filter Confidentiality"
              label="Confidentiality"
              selectedKey={filteredConfidentiality}
              onChange={(
                event: React.FormEvent<HTMLDivElement>,
                item: IDropdownOption | undefined
              ): void => {
                if (item) {
                  setFilteredConfidentiality(item.key as number);
                }
              }}
              options={mapEnumToDropDownValuesWithUndefinedValue(
                ProductConfidentiality,
                ProductConfidentialityToString
              )}
              styles={
                filteredConfidentiality !== undefined
                  ? filterDropdownStylesFiltered
                  : filterDropdownStylesEmpty
              }
            />
            <Dropdown
              placeholder="Filter Type"
              label="Type"
              selectedKeys={filteredTypes}
              onChange={onMetadataFilterChanged(
                filteredTypes,
                setFilteredTypes
              )}
              multiSelect
              options={[
                ...mapEnumToDropDownValuesWithUndefinedValue(
                  ProductType,
                  ProductTypeToString
                ),
                { key: -1, text: "Multiple" }
              ]}
              styles={
                filteredTypes.length > 0
                  ? filterDropdownStylesFiltered
                  : filterDropdownStylesEmpty
              }
            />
            <Dropdown
              placeholder="Filter Backend Hosting"
              label="Backend Hosting"
              selectedKeys={filteredBackendHosting}
              onChange={onMetadataFilterChanged(
                filteredBackendHosting,
                setFilteredBackendHosting
              )}
              multiSelect
              options={mapEnumToDropDownValuesWithUndefinedValue(
                ProductBackendHosting,
                ProductBackendHostingToString
              )}
              styles={
                filteredBackendHosting.length > 0
                  ? filterDropdownStylesFiltered
                  : filterDropdownStylesEmpty
              }
            />
            <Dropdown
              placeholder="Filter Backend Routing"
              label="Backend Routing"
              selectedKeys={filteredBackendRouting}
              onChange={onMetadataFilterChanged(
                filteredBackendRouting,
                setFilteredBackendRouting
              )}
              multiSelect
              options={mapEnumToDropDownValuesWithUndefinedValue(
                ProductBackendRouting,
                ProductBackendRoutingToString
              )}
              styles={
                filteredBackendRouting.length > 0
                  ? filterDropdownStylesFiltered
                  : filterDropdownStylesEmpty
              }
            />
            <Dropdown
              placeholder="Filter Client Authorization"
              label="Client Authorization"
              selectedKeys={filteredAuthorization}
              onChange={onMetadataFilterChanged(
                filteredAuthorization,
                setFilteredAuthorization
              )}
              multiSelect
              options={[
                ...mapEnumToDropDownValuesWithUndefinedValue(
                  ProductAuthorization,
                  ProductAuthorizationToString
                ),
                { key: -1, text: "Multiple" }
              ]}
              styles={
                filteredAuthorization.length > 0
                  ? filterDropdownStylesFiltered
                  : filterDropdownStylesEmpty
              }
            />
            <Dropdown
              placeholder="Filter KeyVault usage"
              label="Uses KeyVault"
              selectedKey={filteredKeyVaultUsage}
              onChange={(_, option) => {
                setFilterdKeyVaultUsage(+option!.key);
              }}
              options={[
                { key: 1, text: "Yes" },
                { key: 2, text: "No" },
                { key: 0, text: "Undefined" }
              ]}
              styles={
                filteredKeyVaultUsage
                  ? filterDropdownStylesFiltered
                  : filterDropdownStylesEmpty
              }
            />
          </Stack>
        )}
      </Stack>
      <div>
        <CommandBar
          items={commandBarItems}
          styles={{ root: { marginTop: 10, padding: 0 } }}
        />
        <div>
          <MarqueeSelection selection={productSelection}>
            <ShimmeredDetailsList
              setKey="items"
              items={currentProductsPage}
              onRenderItemColumn={renderItemColumn}
              columns={columns}
              selectionMode={SelectionMode.single}
              enableShimmer={!ctx.products}
              ariaLabelForShimmer="Loading products"
              ariaLabelForGrid="Product details"
              listProps={shimmeredDetailsListProps}
              onColumnHeaderClick={onColumnClick}
              layoutMode={DetailsListLayoutMode.fixedColumns}
              onRenderRow={onRenderProductListRow}
              selection={productSelection}
              selectionPreservedOnEmptyClick={true}
              onItemInvoked={() => setShowProductPanel(true)}
            />
          </MarqueeSelection>
        </div>
        <div>
          <ReactPaginate
            previousLabel={"← Previous"}
            nextLabel={"Next →"}
            pageCount={Math.ceil(Math.max(currentProducts.length, 1) / PAGE_SIZE)}
            marginPagesDisplayed={1}
            pageRangeDisplayed={2}
            containerClassName={"paginationContainer"}
            previousClassName={
              currentPageNumber < 1 ? "disabledPagination" : ""
            }
            nextClassName={
              currentPageNumber ===
              Math.ceil(Math.max(currentProducts.length, 1) / PAGE_SIZE) - 1
                ? "disabledPagination"
                : ""
            }
            activeClassName={"active"}
            breakLabel={"..."}
            onPageChange={onPageChanged}
            forcePage={currentPageNumber}
          />
        </div>
      </div>
      {selectedProductListEntry !== undefined && (
        <div>
          <ProductFormPanel
            visible={showProductPanel}
            onDismiss={() => {
              setShowProductPanel(false);
            }}
            onSave={(product) => {
              const updatedProductListEntry: IProductListEntry = {
                id: product.id,
                displayName: product.displayName,
                category: product.category,
                confidentiality: product.confidentiality,
                type: product.type,
                backendHosting: product.backendHosting,
                backendRouting: product.backendRouting,
                authorization: product.authorization,
                isUsingKeyVault: product.isUsingKeyVault
              };
              const updatedProductList = [...ctx.products];
              const indexToUpdate = updatedProductList.findIndex(
                (p) => p.id === updatedProductListEntry.id
              );
              updatedProductList[indexToUpdate] = updatedProductListEntry;
              ctx.setProducts(updatedProductList);
            }}
            product={loadedProduct}
            loading={loadingProduct}
          />
          <ProductOwnerPanel
            visible={showProductOwnerPanel}
            onDismiss={() => {
              setShowProductOwnerPanel(false);
            }}
            product={loadedProduct}
            loading={loadingProduct}
          />
          <ClientAppPanel
            visible={showClientAppsPanel}
            onDismiss={() => {
              setShowClientAppsPanel(false);
            }}
            product={loadedProduct}
            loading={loadingProduct}
          />
          <ProductBackendOverviewPanel
            visible={showProductBackendOverviewPanel}
            onDismiss={() => {
              productSelection.setAllSelected(false);
              setSelectedProductListEntry(undefined);
              setShowProductBackendOverviewPanel(false);
            }}
            product={loadedProduct}
            loading={loadingProduct}
          />
          <ProductApisPanel
            visible={showProductApisPanel}
            onDismiss={() => {
              setShowProductApisPanel(false);
            }}
            selectedProductListEntry={selectedProductListEntry}
          />
          <ProductSubscriptionsPanel
            visible={showProductSubscriptionsPanel}
            onDismiss={() => {
              setShowProductSubscriptionsPanel(false);
            }}
            selectedProductListEntry={selectedProductListEntry}
          />
          <ComplianceWarningsPanel
            visible={showComplianceWarningsPanel}
            onDismiss={() => {
              setShowComplianceWarningsPanel(false);
            }}
            selectedProductListEntry={selectedProductListEntry}
          />
        </div>
      )}
    </Stack>
  );
};
