import { IClientAppListEntry } from "models/IClientAppListEntry";
import {
  DetailsRow,
  IColumn,
  IconButton,
  IDetailsListProps,
  IDetailsRowStyles,
  Stack
} from "@fluentui/react";

export const renderItemColumnWithCompanyWarning = (
  item: any,
  index: number | undefined,
  column: IColumn | undefined
) => {
  if (column) {
    const fieldContent = item[
      column.fieldName as keyof IClientAppListEntry
    ] as string;
    switch (column.key) {
      case "company":
        if (fieldContent && fieldContent.length > 0) {
          return <span>{fieldContent}</span>;
        }
        return (
          <Stack horizontal>
            <IconButton
              iconProps={{ iconName: "Warning" }}
              style={{ color: "#000000" }}
              styles={{ icon: { fontSize: 25 } }}
              ariaLabel={`ComplianceWarning`}
            />
            <span
            style={{ color: "#000000", position: "relative", top: "3px" }}
            >
              undefined
            </span>
          </Stack>
        );

      default:
        return <span>{fieldContent}</span>;
    }
  }
  return <span></span>;
};

export const renderItemColumnWithoutCompanyWarning = (
  item: any,
  index: number | undefined,
  column: IColumn | undefined
) => {
  if (column) {
    const fieldContent = item[
      column.fieldName as keyof IClientAppListEntry
    ] as string;
    switch (column.key) {
      case "company":
        if (fieldContent && fieldContent.length > 0) {
          return <span>{fieldContent}</span>;
        }
        return <span>undefined</span>;

      default:
        return <span>{fieldContent}</span>;
    }
  }
  return <span></span>;
};

export const onRenderRowWithCompany: IDetailsListProps["onRenderRow"] = (
    props
) => {
    let customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
        if (
            !props.item["company"] &&
            (props.item["state"] === "Active" || props.item["enabled"])
        ) {
            customStyles.root = customStyles = createErrorCustomStyle('"Please set a company by clicking on Edit!"');
        }
        props.onRenderItemColumn = renderItemColumnWithCompanyWarning;
        return <DetailsRow {...props} styles={customStyles} />;
    }
    return null;
};

export const onRenderWithBlockingWarning: IDetailsListProps["onRenderRow"] = (
  props
) => {
  let customStyles: Partial<IDetailsRowStyles> = {};
  if (props) {
    if(props.item["blocking"])
    {
        customStyles = createErrorCustomStyle('"This is a blocking warning"');
    }
    return <DetailsRow {...props} styles={customStyles} />;
  }
  return null;
};

export const onRenderProductListRow: IDetailsListProps["onRenderRow"] = (
    props
) => {
    let customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
        if (props.item["deletedFromApimDev"] && props.item["deletedFromApimDev"] === true) {
            customStyles = createErrorCustomStyle('"This product was deleted in Dev Environment. Please get in touch with api-support@omv.com"');
        }

        return <DetailsRow {...props} styles={customStyles} />;
    }
    return null;
};
const createErrorCustomStyle = (text:string) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    customStyles.root = {
      backgroundColor: "#FFC4C4",
      position: 'relative', // Ensure the row is a positioned element  
      selectors: {
        ':hover::after': {
            content: text, // Replace with your desired text  
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)', // Center the text both horizontally and vertically  
            backgroundColor: '#FFC4C4',
            color: '#000',
            padding: '5px',
            borderRadius: '3px',
            boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
            whiteSpace: 'nowrap',
            zIndex: 1000, // Ensure it's above other elements  
        },
      },
    };

    return customStyles;
} 

